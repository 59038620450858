import React, { useState, useRef, useEffect } from 'react';
import '../css/burgerMenu.css';
import tele from '../image/telegram.webp';
import twitter from '../image/twitter.webp';

const BurgerMenu = ({ onLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.burger-menu')) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className={`burger-menu ${menuOpen ? 'open' : ''}`}
        onClick={handleMenuToggle}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div
        className={`menu-content ${menuOpen ? 'show' : ''}`}
        ref={menuRef}
      >
        <div className='menu-icon'>
          <div className='menu-txt'>Socials</div>
          <a href="https://x.com/PawZcoin_" target="_blank" rel="noopener noreferrer">
            <img src={twitter} className='icon-twt' alt='twt-icon' />
          </a>
          <a href="https://t.me/pawzportal" target="_blank" rel="noopener noreferrer">
            <img src={tele} className='icon-tele' alt='tele-icon' />
          </a>
          <div className='btn-container'>
            <div className='btn-logout'>
              <button className="btn-90" onClick={onLogout}>
                <div className="blobs"></div>
                <span>Log Out</span>
              </button>
            </div>
            <svg width="0" height="0">
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix
                    in="blur"
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                    result="goo"
                  />
                  <feBlend in="SourceGraphic" in2="goo" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
