import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/joy/Alert';
import AspectRatio from '@mui/joy/AspectRatio';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Warning from '@mui/icons-material/Warning';
import logo from '../image/nobg.png';
import BurgerMenu from './BurgerMenu';
import '../css/dashboard.css';

const Dashboard = () => {
  const [points, setPoints] = useState(0);
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [inputReferralCode, setInputReferralCode] = useState('');
  const [usedReferral, setUsedReferral] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const username = location.state?.username;
      if (!username) {
        navigate('/');
        return;
      }
      const db = getDatabase();
      const userRef = ref(db, `users/${username}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        setPoints(userData.points);
        setReferralCode(userData.referralCode);
        setReferralCount(Object.keys(userData.referrals || {}).length);
        setUsedReferral(userData.usedReferral || false);
      }

      // Fetch leaderboard data user
      const usersRef = ref(db, 'users');
      const usersSnapshot = await get(usersRef);

      if (usersSnapshot.exists()) {
        const usersData = usersSnapshot.val();
        const sortedUsers = Object.keys(usersData)
          .map((key) => ({ username: usersData[key].username, points: usersData[key].points }))
          .sort((a, b) => b.points - a.points)
          .slice(0, 10);

        setLeaderboard(sortedUsers);
      }
    };

    fetchData();
  }, [location, navigate]);

  const handleLogout = () => {
    navigate('/');
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/register?ref=${referralCode}`);
    setAlert({ type: 'success', message: 'Referral link copied to clipboard!' });
  };

  const handleInputReferralCode = async () => {
    if (usedReferral) {
      setAlert({ type: 'warning', message: 'You have already used a referral code.' });
      return;
    }

    const db = getDatabase();
    const usersRef = ref(db, 'users');
    const usersSnapshot = await get(usersRef);

    let isReferralValid = false;
    let referrerUsername = '';
    let referrerPoints = 0;

    if (usersSnapshot.exists()) {
      usersSnapshot.forEach((childSnapshot) => {
        if (childSnapshot.val().referralCode === inputReferralCode) {
          isReferralValid = true;
          referrerUsername = childSnapshot.val().username;
          referrerPoints = childSnapshot.val().points;
          return true;
        }
      });
    }

    if (isReferralValid) {
      const username = location.state.username;
      const userRef = ref(db, `users/${username}`);
      const referrerRef = ref(db, `users/${referrerUsername}`);

      // Update user points and set usedReferral to true
      await update(userRef, { points: points + 100, usedReferral: true });

      // Update referrer's points
      await update(referrerRef, { points: referrerPoints + 275 });

      setPoints(points + 100);
      setUsedReferral(true);

      setAlert({ type: 'success', message: 'Referral code applied successfully!' });
    } else {
      setAlert({ type: 'warning', message: 'Invalid referral code.' });
    }
  };

  return (
    <div className='dashboard-container'>
      {/* Burger Menu Section */}
      <BurgerMenu onLogout={handleLogout} />
      <div className='header'>PawZ</div>
      <div className='logo-header'>
        <img src={logo} className='img-logo' alt='logo-header'></img>
      </div>
      <div className='point'>{points} PoPawz</div>

      {/* Alert Section */}
      {alert && (
        <Stack spacing={2} sx={{ maxWidth: 400, margin: '20px auto' }}>
          <Alert
            size="lg"
            color={alert.type === 'success' ? 'success' : alert.type === 'danger' ? 'danger' : 'warning'}
            variant="solid"
            invertedColors
            startDecorator={
              <AspectRatio
                variant="solid"
                ratio="1"
                sx={{
                  minWidth: 40,
                  borderRadius: '50%',
                  boxShadow: '0 2px 12px 0 rgb(0 0 0/0.2)',
                }}
              >
                <div>
                  {alert.type === 'success' && <Check fontSize="xl2" />}
                  {alert.type === 'danger' && <Warning fontSize="xl2" />}
                  {alert.type === 'warning' && <Warning fontSize="xl2" />}
                </div>
              </AspectRatio>
            }
            endDecorator={
              <IconButton
                variant="plain"
                sx={{
                  '--IconButton-size': '32px',
                  transform: 'translate(0.5rem, -0.5rem)',
                }}
                onClick={() => setAlert(null)}
              >
                <Close />
              </IconButton>
            }
            sx={{ alignItems: 'flex-start', overflow: 'hidden' }}
          >
            <div>
              <Typography level="title-lg">
                {alert.type.charAt(0).toUpperCase() + alert.type.slice(1)}
              </Typography>
              <Typography level="body-sm">{alert.message}</Typography>
            </div>
            <LinearProgress
              variant="solid"
              color={alert.type === 'success' ? 'success' : alert.type === 'danger' ? 'danger' : 'warning'}
              value={100}
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
              }}
            />
          </Alert>
        </Stack>
      )}

      {/* Referral Link Section */}
      <div className='referral-link-container'>
        <input
          type='text'
          className='referral-link-input'
          value={`${window.location.origin}/register?ref=${referralCode}`}
          readOnly
        />
        <button className='btn-41' onClick={handleCopyReferralLink}>
          <span>Copy Link</span>
        </button>
      </div>

      {/* Input Referral Code Section */}
      <div className='input-referral-code-container'>
        <input
          type='text'
          className='input-referral-code-input'
          placeholder='Enter referral code'
          value={inputReferralCode}
          onChange={(e) => setInputReferralCode(e.target.value)}
          disabled={usedReferral}
        />
        <button
          className={`apply-btn ${usedReferral ? 'disabled' : ''}`}
          onClick={handleInputReferralCode}
          disabled={usedReferral}
        >
          Apply Code
        </button>
      </div>

      {/* Leaderboard Section */}
      <div className='leaderboard-container'>
        <div className='lb-text'>Leaderboard</div>
        {leaderboard.map((user, index) => (
          <div className='leaderboard-item' key={user.username}>
            <div className='leaderboard-user'>
              <div className='leaderboard-username'>{user.username}</div>
              <div className='leaderboard-points'>{user.points} PoPawz</div>
            </div>
            <div className='leaderboard-rank'>#{index + 1}</div>
          </div>
        ))}
      </div>
      <div className='footer'>© 2024 PawZ - All Rights Reserved.</div>
    </div>
  );
};

export default Dashboard;
