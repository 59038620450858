import React from 'react';
import { getDatabase, ref, get, child, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Swal from 'sweetalert2';
import '../css/login.css';
import logo from '../image/nobg.png';


const Login = () => {
  const navigate = useNavigate();
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const usernameFromEmail = user.email.split('@')[0];
      const dbRef = ref(getDatabase());
      const snapshot = await get(child(dbRef, `users/${usernameFromEmail}`));

      if (snapshot.exists()) {
        Swal.fire({
          icon: 'success',
          title: 'Login Success!',
          text: 'You have successfully logged in.',
          background: 'aliceblue',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        await set(ref(database, `users/${usernameFromEmail}`), {
          username: usernameFromEmail,
          email: user.email,
          points: 0,
          referralCode: Math.random().toString(36).substring(7),
        });
        Swal.fire({
          icon: 'success',
          title: 'User registered and logged in',
          text: 'You have successfully registered and logged in.',
          background: 'aliceblue',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
      navigate('/dashboard', { state: { username: usernameFromEmail } });
    } catch (error) {
      console.error("Error logging in user with Google:", error);
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'There was an error logging in. Please try again later.',
        background: 'aliceblue',
      });
    }
  };
  return (
    <div className='parent-container'>
    <div className='login-container'>
      <div className='header-txt'>Sign In</div>
      <div className='logo-header'>
          <img src={logo} className='logo'alt='logo-cactus'></img>
        </div>
      <div className='btn-signin'>
      <button className='btn-59' onClick={handleGoogleLogin}><span>Sign In with Google</span> </button>
      </div>
      <div className='footer'>
        <p>Don't have an account? <a href='/register'>Register here</a></p>
      </div>
    </div>
    </div>
  );
};

export default Login;
