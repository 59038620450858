import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, child, update, push } from 'firebase/database';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Swal from 'sweetalert2';
import logo from '../image/nobg.png';
import '../css/register.css';

const Register = () => {
  const [referralCode, setReferralCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refCode = params.get('ref');
    if (refCode) {
      setReferralCode(refCode);
    }
  }, [location]);

  const handleGoogleRegister = async () => {
    const provider = new GoogleAuthProvider();

    try {
      console.log('Attempting Google Sign-In');
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log('User signed in:', user);

      const usernameFromEmail = user.email.split('@')[0];
      const db = getDatabase();
      const userRef = ref(db, `users/${usernameFromEmail}`);
      console.log('Checking if user exists in database');

      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        console.log('User already registered');
        Swal.fire({
          icon: 'info',
          title: 'User already registered',
          text: 'This user is already registered. Redirecting to home page...',
          background: 'aliceblue',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        navigate('/');
      } else {
        const userData = {
          username: usernameFromEmail,
          email: user.email,
          points: 50,
          referralCode: Math.random().toString(36).substr(2, 9),
          referrals: {},
          usedReferral: false,
        };
        console.log('User data prepared:', userData);

        if (referralCode) {
          console.log('Referral code provided:', referralCode);
          const dbRef = ref(db);
          const referralSnapshot = await get(child(dbRef, `users`));

          let isReferralValid = false;

          referralSnapshot.forEach((childSnapshot) => {
            if (childSnapshot.val().referralCode === referralCode) {
              const referralUsername = childSnapshot.key;
              const referralData = childSnapshot.val();
              const newPoints = referralData.points + 275;

              update(ref(db, `users/${referralUsername}`), {
                points: newPoints,
              });

              userData.referredBy = referralCode;

              const newReferralKey = push(child(ref(db), `users/${referralUsername}/referrals`)).key;
              const updates = {};
              updates[`users/${referralUsername}/referrals/${newReferralKey}`] = usernameFromEmail;

              update(ref(db), updates);

              isReferralValid = true;
              return true;
            }
          });

          if (!isReferralValid) {
            console.log('Invalid referral code');
            Swal.fire({
              icon: 'error',
              title: 'Invalid referral code',
              text: 'The referral code you entered is not valid. Please try again.',
              background: 'aliceblue',
            });
            return;
          }
        }

        console.log('Setting user data in the database');
        await set(userRef, userData);

        Swal.fire({
          icon: 'success',
          title: 'Registration successful!',
          text: 'You have successfully registered. Redirecting to home page...',
          background: 'aliceblue',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        navigate('/');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Registration failed',
        text: `There was an error during registration: ${error.message}`,
        background: 'aliceblue',
      });
    }
  };

  return (
    <div className="parent-container">
      <div className='register-container'>
        <div className='border-img'></div>
        <div className='header-txt'>Sign Up</div>
        <div className='logo-header'>
          <img src={logo} className='logo' alt='logo-cactus'></img>
        </div>
        <div className='btn-signup'>
          <button className='btn-59' onClick={handleGoogleRegister}><span>Sign Up with Google</span></button>
        </div>
        <div className='footer'>
          <p>Already have an account? <a href='/'>Login here</a></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
